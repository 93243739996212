/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import UiApp from "./ui/UiApp";
import "./App.css";
import UserContext from "./UserContext/UserContext";
import Login from "./Login/Login";
import { axiosInstance } from "./config/api";

function Outer() {
  const contextType = useContext(UserContext);
  const [patientInfo, setPatientInfo] = useState({});
  const [patientId, setPatientId] = useState(-1);

  const { setUser, user, setToken, token, setImgLink } = contextType;
  console.log(token);

  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };

  // useEffect(() => {

  //     axiosInstance
  //       .get( `/Api/people/Peopledocuments/1`,     config   )
  //       .then((response) => {
  //         console.log("response.data.data" , response.data.data);
  //         console.log("response.data.data" , response.data.data);
  //            setImgLink(response?.data?.data?.fileUrl);
  //           setPatientId(response?.data?.data?.patientId);
  //           console.log("patientId" , patientId);
  //           console.log("imgLink" , imgLink);

  //       }).catch((err)=> {
  //         console.log("there is erorr" , err);
  //       })

  // }, []);

  return !user.isAuthenticated && localStorage.getItem("authToken") == null ? (
    !user.isAuthenticated && localStorage.getItem("authToken") == null ? (
      <Login setUser={setUser} user={user} setToken={setToken} token={token} />
    ) : (
      <UiApp
        setPatientId={setPatientId}
        token={token}
        patientId={patientId}
        patientInfo={patientInfo}
        user={user}
      />
    )
  ) : (
    <UiApp
      setPatientId={setPatientId}
      token={token}
      patientId={patientId}
      patientInfo={patientInfo}
      user={user}
    />
  );
}

export default Outer;
