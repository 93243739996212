/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import SideNav, { Toggle, Nav, NavText, NavItem, NavIcon } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import './patient.css'
import DatePick from "./DatePicker";
import axios from "axios";
import toggle from '../../Vector.png';
import cal from '../../Vector3.png';
import mentor from '../../monitor-mobbile.png';
import global from '../../global-edit.png';
import filter from '../../filter.png';
import userSq from '../../user-square.png'
import computer from '../../directbox-receive.png'
import uplo from '../../Group 1171275782.png'
import filt from '../../Group 1171275786.png'
import cale from '../../calendar.png'
import downl from '../../Group 1171275784.png'
import world from '../../Group 1171275780 (1).png'
import glob from '../../Group 1171275780.png'
import pat from '../../Group 1171275788.png'
import { FaBars, FaTimes, FaHome, FaInfoCircle, FaEnvelope, FaQuestionCircle } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfiniteScroller from "./Autocomplete/PatientSelect";


function ItemComponent(props) {
  const { src, text, active, selectVisit, patientSelect, setPatientShow, patientShow, startDate, setStartDate } = props

  const [returnInput, setReturnInput] = useState(true)

  const setShow = () => {
    if (patientSelect)
      setPatientShow(!patientShow)
  }

  return (
    <>
      {!selectVisit ?
        <div className="dddf" onClick={setShow}>
          <img style={{ width: '36px', height: '36px' }} src={src} />
          <span>{text}</span>
          <div className={active ? "sds" : 'sdsInactive'}></div>
        </div>
        : returnInput ? <div onClick={() => setReturnInput(!returnInput)} className="dddf">
          <img style={{ width: '36px', height: '36px' }} src={src} />
          <span>{text}</span>
          <div className={active ? "sds" : 'sdsInactive'}></div>
        </div> : <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', margin: 'auto' }}>
          <DatePicker placeholderText='Select Date of Visit' selected={startDate} onChange={(date) => setStartDate(date)} />
          <font className="iconBttttn" onClick={() => setReturnInput(!returnInput)}>X</font>
        </div>
      }

    </>
  )
}

function SideNavBar(props) {

  const { onButtonLocalFile, modalShow, onModalUrlShow, setVisitDate, refBtn, handleClickLoadF } = props

  const [isActive, setIsActive] = useState(false);
  const [patientShow, setPatientShow] = useState(false);
  const [startDate, setStartDate] = useState(null);


  console.log("patientShowpatientShow" , patientShow);
  function toggleSidebar() {
    setIsActive(!isActive);
  }

  // eslint-disable-next-line no-unused-vars
  // const { setPatientInfo, handleClickLoadF, showModalUrl, onModalUrlHide, onModalUrlShow, patientInfo: patientData, patientId: patientID, refBtn, visitDate, setVisitDate } = props

  const [isVisible, setIsVisible] = useState(false)
  const [resultList, setResultList] = useState([])
  const [selectId, setSelectId] = useState(-1)

  // let config = {
  //   headers: {
  //     'Authorization': 'Bearer ' +
  //       // eslint-disable-next-line max-len
  //       localStorage.getItem('authToken')
  //   }
  // }

  // useEffect(() => {
  //   if (patientID !== -1)
  //     setSelectId(patientID)
  // }, [patientID])

  // useEffect(() => {
  //   // setPatientInfo(selectId);
  //   if (selectId !== -1) {
  //     axios
  //       .get(`https://localhost:44392/Api/People/Details/${selectId}`, config)
  //       // eslint-disable-next-line no-unused-vars
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setPatientInfo(res.data.data)
  //         }
  //         // setPatientInfo(res.data.data);
  //       });
  //   }
  //   setPatientInfo(patientData)
  // }, [selectId])



  return (
    <>
      <div className={`sidebar ${isActive ? 'active' : 'inactive'}`}>
        <button style={{ backgroundColor: 'inherit', border: 'none', margin: '50px 13px 50px 12px' }} onClick={toggleSidebar}>
          {isActive ? <FaTimes /> : <img src={toggle} style={{ width: '85%' }} />}
        </button>
        <ul>
          <li>
            <div className="itemLep" />

            {isActive ? <ItemComponent startDate={startDate} setStartDate={setStartDate} selectVisit src={cale}
              text="Select the Visit Date" /> : <a href="#">
              <div className={isActive ? 'activeImage' : 'inactiveImage'}>
                <span className="tooltiptext">Select Date</span>
                <img src={cal} />
              </div>
            </a>}

          </li>
          <li>
            <div className="itemLep" />

            {isActive ? <>
              <ItemComponent patientSelect={true} setPatientShow={setPatientShow}
                patientShow={patientShow} style={{ cursor: 'pointer' }} src={pat} text='Patients' /> 
                {patientShow ? <InfiniteScroller handleClickLoadF = {handleClickLoadF} 
                setSelectId={setSelectId} refBtn={refBtn} startDate={startDate} onModalUrlShow={onModalUrlShow} 
                isVisible = {isVisible} setIsVisible = {setIsVisible} setVisitDate={setVisitDate} /> : null}
            </>
              : <a href="#">
                <div className={isActive ? 'activeImage' : 'inactiveImage'}>
                  <span className="tooltiptext1">Show Patients</span>

                  <img src={userSq} />
                </div>
              </a>}

          </li>
          <li>
            {!isActive && <div className="itemLep" />}
            {isActive ? null :
              <a style={{ cursor: 'pointer' }} onClick={modalShow}>
                <div className={isActive ? 'activeImage' : 'inactiveImage'} >
                  <span className="tooltiptext2">Load</span>

                  <img src={global} />

                </div>
              </a>}


          </li>
          <li>
            {!isActive && <div className="itemLep" />}
            {isActive ? null : <a style={{ cursor: 'pointer' }} onClick={onButtonLocalFile}>
              <div className={isActive ? 'activeImage' : 'inactiveImage'}>
                <span className="tooltiptext3">Upload From Desktop</span>

                <img src={mentor} />
              </div>
            </a>}


          </li>
          <li>
            {!isActive && <div className="itemLep" />}
            {isActive ? null : <a href="#">

              <div className={isActive ? 'activeImage' : 'inactiveImage'} >
                <span className="tooltiptext4">Download</span>

                <img src={computer} />

              </div>
            </a>}

          </li>
          <li>
            {!isActive && <div className="itemLep" />}
            {isActive ? null : <a href="#">
              <div className={isActive ? 'activeImage' : 'inactiveImage'} >
                <span className="tooltiptext4">Filter</span>

                <img src={filter} />
              </div>
            </a>}

          </li>
        </ul>
      </div>
    </>
    // <SideNav className={isVisible ? 'opened' : 'closed'}
    //   expanded={isVisible}>
    //   <Toggle
    //     onClick={() => {
    //       setIsVisible(!isVisible);
    //     }}
    //   />
    //   <SideNav.Nav defaultSelected="home">
    //     <NavItem eventKey="home">
    //       <NavIcon>
    //         <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
    //       </NavIcon>
    //       <NavText>
    //         Home
    //       </NavText>
    //     </NavItem>
    //     <NavItem eventKey="cal">
    //       <NavIcon>
    //         <img src={cal} style={{ fontSize: '1.75em' }} />
    //       </NavIcon>
    //       <NavText>
    //         Charts
    //       </NavText>
    //     </NavItem>
    //   </SideNav.Nav>
    //   <Nav style={{ color: 'black' }}>
    //     {isVisible && (
    //       <>
    //         <p style={{ color: 'black', textAlign: 'center', margin: '20px auto 0 auto', fontSize: '20px', fontWeight: 'bold', paddingBottom: '50px' }} >Select Visit</p>
    //         <DatePick refBtn={refBtn} onModalUrlHide={onModalUrlHide} onModalUrlShow={onModalUrlShow} setVisitDate={setVisitDate} visitDate={visitDate}
    //           handleClickLoadF={handleClickLoadF} setIsVisible={setIsVisible} setResultList={setResultList} resultList={resultList} setSelectId={setSelectId} />
    //       </>
    //     )}
    //   </Nav>
    // </SideNav>
  );
}

export default SideNavBar;
