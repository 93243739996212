/**
 * @fileOverview UiModalDicomTags
 * @author Epam
 * @version 1.0.0
 */


// ********************************************************
// Imports
// ********************************************************

import React from 'react';
import { connect } from 'react-redux';

import { Modal, Table } from 'react-bootstrap';
import UserContext from '../UserContext/UserContext';
import { axiosInstance, getAuthorizationHeader } from '../config/api';

// ********************************************************
// Const
// ********************************************************

// ********************************************************
// Class
// ********************************************************

/**
 * Class UiModalDicomTags some text later...
 */
class UiModalDicomTags extends React.Component {

  static contextType = UserContext

  /**
   * @param {object} props - props from up level object
   */
  constructor(props) {
    super(props);
    this.onModalHide = this.onModalHide.bind(this);

    this.state = {
      showModalDemo: false,
      currentSlice: 0,
      names: [],
      values: [],
    };
  }

  onModalHide() {
    this.setState({ showModalDemo: false });
  }

  onSelectSlice(evt) {
    // const nam = evt.target.name;
    const val = evt.target.value;
    // console.log(`onSelectSlice. name = ${nam} val = ${val}`);
    const arr = val.split(' ');
    const ind = parseInt(arr[1]);
    console.log(`onSelectSlice. index = ${ind}`);
    this.setState({ currentSlice: ind });
  }

  componentWillMount() {

    axiosInstance.get("/Api/People/PatientPastMedicalHistory/1" , {
      headers: {
         ...getAuthorizationHeader()//the token is a variable which holds the token
      }
     })
      .then((response) => {
        if (response.status === 200) {
          console.log(">>>", Object.keys(response?.data?.data[0])?.map((ee) => ee?.replace(/([A-Z])/g, ' $1').trim()));
          console.log("|||", Object.values(response?.data?.data[0]));
          this.setState({ names: Object.keys(response?.data?.data[0]).filter((kk , inde) => inde !== 33)?.map((ee) => ee?.replace(/([A-Z])/g, ' $1').trim()) })
          this.setState({ values: Object.values(response?.data?.data[0])?.filter((ee, ind) => ind !== 33 )?.map((km) => km?.toString()) })
        }
      });
  }


  render() {
    const stateVis = this.props.stateVis;
    const onHideFunc = this.props.onHide;
    

    const jsxModalDemo =
      <Modal show={stateVis} onHide={onHideFunc} size="xl" >
        <Modal.Header closeButton>
          <Modal.Title>
            Past Medical Health
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <h4>Patient Medical History</h4>
          <br />
          <br />
          <br />

          <Table striped bordered responsive>
            <thead>

              <tr>
                <th>
                  Attribute Name
                </th>
                <th>
                  Attribute Value
                </th>
              </tr>

            </thead>
            <tbody>
              {this?.state?.names?.map((d, i) => {
                console.log("this?.state?.values[i]", this?.state?.values[i]);
                // return <tr key={i}><td>{strTag}</td><td>{i}</td><td>{i}</td></tr>;
                return <tr key={i}><td>{d}</td><td>{this?.state?.values[i]}</td></tr>;
              })}
            </tbody>

          </Table>

        </Modal.Body>
      </Modal>
    return jsxModalDemo;
  } // end render
} // end class

export default connect(store => store)(UiModalDicomTags);
