/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
// import PropTypes from 'prop-types';
import { BsFillHeartFill } from "react-icons/bs";
import { VscFileSubmodule } from "react-icons/vsc";
import "./patient.css";
import { MdPlace } from "react-icons/md";
import { AiFillCreditCard } from "react-icons/ai";
import { GoDeviceMobile } from "react-icons/go";
import { RxAvatar } from "react-icons/rx";
import UserContext from "../UserContext/UserContext";
import Modal from "@mui/joy/Modal";
import { AiFillCheckCircle } from "react-icons/ai";
import AlgModal from "./AlgModal";
import PrevModal from "./PrevModal";
import def from "../../default.jpg";
import { Avatar } from "@mui/joy";
import useEdit from "../../user-edit.png";
import useEdit1 from "../../user-edit1.png";
import idCard from "../../personalcard.png";
import idCard1 from "../../personalcard1.png";
import cal from "../../calendar-2.png";
import cal1 from "../../calendar-21.png";
import calc from "../../calculator.png";
import calc1 from "../../calculator1.png";
import clock from "../../clock.png";
import clock1 from "../../clock1.png";
import location from "../../location.png";
import location1 from "../../location1.png";
import ss from "../../sss.png";
import { axiosInstance, getAuthorizationHeader } from "../config/api";

function HeaderPatient(props) {
  const { visitDate } = props;
  const [patientId, setPatientId] = useState(-1);
  const [patientDetails, setPatientDetails] = useState([]);
  const [patientPrevDetails, setPatientPrevDetails] = useState([]);
  const [algModal, setAlgModal] = useState(false);
  const [prevModal, setPrevModal] = useState(false);

  const tt = useContext(UserContext);

  const { token, user, userselected, setUserSelected } = tt;
  let config = {
    headers: {
      Authorization:
        "Bearer " +
        // eslint-disable-next-line max-len
        localStorage.getItem("authToken"),
    },
  };
  console.log("config is : ", config);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // user > 0 &&
  // useEffect(() => {
  //   axiosInstance
  //     .get(`/Api/People/Details/${user}`, {
  //       headers: {
  //         ...getAuthorizationHeader(), //the token is a variable which holds the token
  //       },
  //     })
  //     .then((res) => {
  //       console.log("from header after then");
  //       if (res.status === 200) {
  //         setuserselected(res.data.data);
  //         console.log("res.data for setuserselected", res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log( "there is erorrsssssssssssssssssssssssssssssssssssssssss", err );
  //     });


  //     console.log("userselected" , userselected);
  //   if (user > 0) {
  //     axiosInstance
  //       .get(`/Api/People/PatientFoodAllergies/${user}`, {
  //         headers: {
  //           ...getAuthorizationHeader(), //the token is a variable which holds the token
  //         },
  //       })
  //       .then((res) => {
  //         if (res.status === 200 && res.data.data.length > 0) {
  //           setPatientDetails(res.data.data.map((ee) => ee.name));
  //         }
  //       });

  //     axiosInstance
  //       .get(`/Api/People/PatientPastMedicalHistory/${user}`, {
  //         headers: {
  //           ...getAuthorizationHeader(), //the token is a variable which holds the token
  //         },
  //       })
  //       .then((res) => {
  //         if (res.status === 200 && res.data.data.length > 0) {
  //           let newObj = {};
  //           for (const [key, value] of Object.entries(res.data.data[0])) {
  //             if (value === true) {
  //               newObj[key] = value;
  //             }
  //           }
  //           setPatientPrevDetails(Object.keys(newObj));
  //         }
  //       });
  //   }
  // }, [user]);


 

 console.log("userselected", userselected);

  return (
    <div
      className={
        visitDate.visitStatus != "Closed"
          ? "containerPatient"
          : "containerPatientClosed"
      }>
      <div
        className={visitDate.visitStatus != "Closed" ? "curve" : "curveClosed"}
      />
      <div className="image-container">
        {/* <img src="your-image-source" alt="your-image-description" /> */}
        <Avatar
          src={userselected?.img == null ? def : userselected?.img}
          variant="square"
          sx={{
            width: 290,
            height: 290,
            borderRadius: 20,
            margin: "auto 0",
            border: "5px solid rgba(0, 129, 193, 0.15)",
          }}
        />
        {visitDate.visitStatus == "Closed" ? (
          <div className="overlay">
            <p>Closed</p>
          </div>
        ) : null}
      </div>
      <div className="patientInfo">
        {/* -=------------------------------------ First UL --------------------------------------- */}
        <ul
          className="fa-ul"
          style={{ width: "100%", justifySelf: "flex-start" }}>
          <li
            style={{
              fontSize: "19px",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#0081C1",
            }}>
            <i>
              <img
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
                src={visitDate.visitStatus != "Closed" ? useEdit : useEdit1}
              />
            </i>
            Patient's Name :{" "}
            <p style={{ display: "inline-block", color: "#000000" }}>
              {userselected?.firstName
                ? userselected?.firstName +
                  " " +
                  userselected?.fatherName +
                  " " +
                  userselected?.grandfatherName +
                  " " +
                  userselected?.familyName
                : "" || "No data"}
            </p>
          </li>

          <li
            style={{
              fontSize: "19px",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#0081C1",
            }}>
            <i>
              <img
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
                src={visitDate.visitStatus != "Closed" ? idCard : idCard1}
              />
            </i>
            Patient's ID :{" "}
            <p style={{ display: "inline-block", color: "#000000" }}>
              {userselected?.idn || "No data"}
            </p>
          </li>

          <li
            style={{
              fontSize: "19px",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#0081C1",
            }}>
            <i>
              <img
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
                src={visitDate.visitStatus != "Closed" ? cal : cal1}
              />
            </i>
            Visit's Date :{" "}
            <p style={{ display: "inline-block", color: "#000000" }}>
              {userselected?.visitDate?.split("T")[0] || "No data"}
            </p>
          </li>
        </ul>
        {/*  ----------------------------------------- Second UL ------------------------------------------------- */}
        <ul
          className="fa-ul"
          style={{ width: "100%", justifySelf: "flex-start" }}>
          <li
            style={{
              fontSize: "19px",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#0081C1",
            }}>
            <i>
              <img
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
                src={visitDate.visitStatus != "Closed" ? location : location1}
              />
            </i>
            Place :{" "}
            <p style={{ display: "inline-block", color: "#000000" }}>
              {userselected?.addressDetails || "No data"}
            </p>
          </li>

          <li
            style={{
              fontSize: "19px",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#0081C1",
            }}>
            <i>
              <img
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
                src={visitDate.visitStatus != "Closed" ? calc : calc1}
              />
            </i>
            Mobile Number :{" "}
            <p style={{ display: "inline-block", color: "#000000" }}>
              {userselected?.mobileNo || "No data"}
            </p>
          </li>

          <li
            style={{
              fontSize: "19px",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#0081C1",
            }}>
            <i>
              <img
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
                src={visitDate.visitStatus != "Closed" ? clock : clock1}
              />
            </i>
            Visit's Time :{" "}
            <p style={{ display: "inline-block", color: "#000000" }}>
              {userselected?.visitTime || "No data"}
            </p>
          </li>
        </ul>

        {/*  ----------------------------------------- TOOLTIP ------------------------------------------------- */}

        <div
          className={
            visitDate.visitStatus != "Closed"
              ? "tooltipItem"
              : "tooltipItemClosed"
          }>
          <p
            className={
              visitDate.visitStatus != "Closed"
                ? "patientAlg"
                : "patientAlgClosed"
            }>
            {
              <BsFillHeartFill
                className="hra"
                onClick={() => {
                  setPatientId(userselected?.patientId);
                  setPrevModal(!algModal);
                }}
                color={visitDate.visitStatus != "Closed" ? "white" : "#EB455F"}
                style={{
                  fontSize: "35px",
                  cursor: userselected?.firstName ? "pointer" : "unset",
                }}
              />
            }
            <span>Medical History</span>
          </p>
        </div>
        <div
          className={
            visitDate.visitStatus != "Closed"
              ? "tooltipItem1"
              : "tooltipItem1Closed"
          }>
          <p
            className={
              visitDate.visitStatus != "Closed"
                ? "patientAlg1"
                : "patientAlg1Closed"
            }>
            {
              <VscFileSubmodule
                onClick={() => {
                  setPatientId(userselected?.patientId);
                  setAlgModal(!algModal);
                }}
                color={visitDate.visitStatus != "Closed" ? "white" : "#FF9C00"}
                style={{
                  fontSize: "35px",
                  cursor: userselected?.firstName ? "pointer" : "unset",
                }}
              />
            }
            <span>Alergies</span>
          </p>
        </div>
      </div>
      {user > 0 && (
        <>
          <AlgModal
            open={algModal}
            setOpen={setAlgModal}
            patientDetails={patientDetails}
          />
          <PrevModal
            open={prevModal}
            setOpen={setPrevModal}
            patientDetails={patientPrevDetails}
          />
        </>
      )}
    </div>
  );
}

export default HeaderPatient;
