import React from 'react';
import { connect } from 'react-redux';

import './App.css';
import { UserProvider } from './UserContext/UserContext';
import Outer from './Outer';


class App extends React.Component {

  render() {

    const user = { username: 'code4palestine@gmail.com', isAuthenticated: true }

    return (
      <UserProvider value={user}>
        <Outer />
      </UserProvider>
    )
  }
}

export default connect(store => store)(App);
