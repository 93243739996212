import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import logo from "../../logo1.png";
import "./style.css";
import { Formik } from "formik";
import * as yup from "yup";
import UserContext from "../UserContext/UserContext";
import Loading from "../ui/Loading/Loading";
import { AuthTokenStorage } from "../UserContext/AuthTokenStorage";
import { axiosInstance } from "../config/api";

export default class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      err: null,
      authToken: AuthTokenStorage.getAuthToken(),
    };
  }

  setIsLoading(arg) {
    this.setState({ isLoading: arg });
  }

  handleLogin = (token) => {
    // make API call to authenticate user and get token
    AuthTokenStorage.setAuthToken(token);
    localStorage.setItem("authToken", token);
    console.log(" this.state", this.state);
    this.setState({ authToken: token });
  };

  render() {
    const { setUser, setToken } = this.context;

    const validationSchema = yup.object().shape({
      usernameOrEmail: yup.string().required("*Email or UserName is required"),
      password: yup
        .string()
        .min(8, "*Password must have at least 8 characters")
        .max(50, "*Password can't be longer than 50 characters")
        .required("*Password is required"),
    });

    return (
      <>
        <div className="log">
          <div className="logoWrap">
            <img src={logo} className="logoImg" alt="logo" />
            <h4 className="formText">Sign In To iDoctor PACS Solutions</h4>
          </div>
          <Formik
            initialValues={{ usernameOrEmail: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              this.setIsLoading(true);

              var formData = new FormData();

              formData.append("usernameOrEmail", values.usernameOrEmail);
              formData.append("password", values.password);
              // Resets form after submission is complete
              resetForm({ usernameOrEmail: "", password: "" });

              // Sets setSubmitting to false after form is reset
              setSubmitting(false);
              axiosInstance
                .post("/api/Account/token", formData)
                .then((response) => {
                  console.log("response", response);
                  if (response.data.token) {
                    const newUser = {
                      userName: response.data.userName,
                      isAuthenticated: response.data.isAuthenticated,
                    };
                    setUser(newUser);
                    setToken(response.data.token);
                    this.handleLogin(response.data.token);
                    this.setIsLoading(false);
                  }
                })
                .catch((err) => {
                  this.setState({ err: err.message });
                  this.setIsLoading(false);
                  console.log(err);
                });
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="form">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    className={
                      touched.usernameOrEmail && errors.usernameOrEmail
                        ? "error"
                        : "control"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.usernameOrEmail}
                    type="text"
                    name="usernameOrEmail"
                    placeholder="Email Address"
                  />
                  {touched.usernameOrEmail && errors.usernameOrEmail ? (
                    <div className="error-message">
                      {errors.usernameOrEmail}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  className="mb-3 groupPass"
                  controlId="formBasicPassword">
                  <Form.Control
                    className={
                      touched.password && errors.password ? "error" : "control"
                    }
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    type="password"
                    placeholder="Password"
                  />
                  {touched.password && errors.password ? (
                    <div className="error-message">{errors.password}</div>
                  ) : null}
                  {this.state.err && (
                    <div className="error-message">{this.state.err}</div>
                  )}{" "}
                </Form.Group>
                <Form.Group
                  className="mb-3 groupActions"
                  controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember me" />
                  <Button variant="link">Forgot Password ?</Button>
                </Form.Group>
                <Button
                  disabled={isSubmitting}
                  style={{ margin: "auto" }}
                  className="buttn"
                  variant="primary"
                  type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        {this.state.isLoading && <Loading />}
      </>
    );
  }
}
