/**
 * @fileOverview UiReportMenu
 * @author Epam
 * @version 1.0.0
 */

// ********************************************************
// Imports
// ********************************************************

import React from 'react';
import { connect } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';

import UiModalDicomTags from './UiModalDicomTags';
import Screenshot from '../engine/utils/Screenshot';
import ModeView from '../store/ModeView';

// ********************************************************
// Const
// ********************************************************

// ********************************************************
// Class
// ********************************************************

/**
 * Class UiReportMenu some text later...
 */
class UiReportMenu extends React.Component {
  // constructor
  constructor(props) {
    super(props);

    this.onModalDicomTagsShow = this.onModalDicomTagsShow.bind(this);
    this.onModalDicomTagsHide = this.onModalDicomTagsHide.bind(this);
    this.onModalAlgShow = this.onModalAlgShow.bind(this);
    this.onModalAlgHide = this.onModalAlgHide.bind(this);
    this.onModalScreenshot = this.onModalScreenshot.bind(this);

    this.state = {
      showModalDicomTags: false,
      showModalAlg: false
    };
  }

  onModalDicomTagsShow() {
    this.setState({ showModalDicomTags: true });
  }

  onModalDicomTagsHide() {
    this.setState({ showModalDicomTags: false });
  }

  onModalAlgShow() {
    this.setState({ showModalAlg : true })
  }

  onModalAlgHide() {
    this.setState({ showModalAlg : false })
  }

  onModalScreenshot() {
    const SHOT_W = 800;
    const SHOT_H = 600;

    const store = this.props;
    const modeView = store.modeView;
    if (modeView === ModeView.VIEW_2D) {
      const gra2d = store.graphics2d;
      Screenshot.makeScreenshot(gra2d, SHOT_W, SHOT_H);
    } else if ((modeView === ModeView.VIEW_3D) || (modeView === ModeView.VIEW_3D_LIGHT)) {
      const volRender = store.volumeRenderer;
      Screenshot.makeScreenshot(volRender, SHOT_W, SHOT_H);
    } else {
      console.log('onModalScreenshot. not implemented yet');
    }
  }

  // invoked after render
  componentDidMount() {
  }

  render() {
    const jsxReportMenu = 
      <NavDropdown id="save-nav-dropdown" 
        title={
          <div style={{ display: 'inline-block', color: 'white' }}> 
            <i className="fas fa-book" style={{ marginRight: '3px' }}></i>
            Patient Reports
          </div>
        } >
        <NavDropdown.Item onClick={this.onModalDicomTagsShow} >
          <i className="fas fa-clipboard-list" style={{ marginRight: '3px' }}></i>
          Show Medical History
        </NavDropdown.Item>

        <NavDropdown.Item >
          <i className="fas fa-clipboard-list" style={{ marginRight: '3px' }}></i>
          Allergies
        </NavDropdown.Item>

        <UiModalDicomTags stateVis={this.state.showModalDicomTags}
          onHide={this.onModalDicomTagsHide} />

      </NavDropdown>;    

    return jsxReportMenu;
  }
}

export default connect(store => store)(UiReportMenu);
