const ModeView = {
  VIEW_NA: -1,
  VIEW_MPR: 0,
  VIEW_2D: 1,
  VIEW_3D_LIGHT: 2,
  VIEW_3D: 3,
  VIEW_COUNT: 4,
  VIEW_PEOPLE: 5
};

export default ModeView;
