/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';

import { Navbar, Container, ProgressBar, Row, Col, Nav } from 'react-bootstrap';
import StoreActionType from '../store/ActionTypes';

import UiMain from './UiMain';
import UiOpenMenu from './UiOpenMenu';
import UiViewMode from './UiViewMode';
import UiAbout from './UiAbout';
import UiSaveMenu from './UiSaveMenu';
import UiReportMenu from './UiReportMenu';
import UiFilterMenu from './UiFilterMenu';
import UiErrConsole from './UiErrConsole';
import ModeView from '../store/ModeView';
import '../Login/style.css'
import './patient.css'

import BrowserDetector from '../engine/utils/BrowserDetector';
// import UiUser from './UiUser';
import HeaderPatient from './HeaderPatient';
import SideNavBar from './SideNavBar';
import { FileView } from './FileView';
import UserContext from '../UserContext/UserContext';

class UiApp extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.button = React.createRef();
    this.onShowModalText = this.onShowModalText.bind(this);
    this.onHideModalText = this.onHideModalText.bind(this);
    this.onSetPatientInfo = this.onSetPatientInfo.bind(this);
    this.handleClickLoadF = this.handleClickLoadF.bind(this)

    this.onShowModalAlert = this.onShowModalAlert.bind(this);
    this.onHideModalAlert = this.onHideModalAlert.bind(this);
    this.onOpenModalFile = this.onOpenModalFile.bind(this);
    this.onCloseModalFile = this.onCloseModalFile.bind(this);
    this.onModalUrlHide = this.onModalUrlHide.bind(this);
    this.onModalUrlShow = this.onModalUrlShow.bind(this);
    this.setVisitDate = this.setVisitDate.bind(this);

    this.doShowProgressBar = this.doShowProgressBar.bind(this);
    this.doHideProgressBar = this.doHideProgressBar.bind(this);
    this.buildFileSelector = this.buildFileSelector.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this)
    this.onButtonLocalFile = this.onButtonLocalFile.bind(this)
    this.doSetProgressBarRatio = this.doSetProgressBarRatio.bind(this);

    this.m_modalText = null;
    this.m_store = null;
    this.m_fileNameOnLoad = '';

    this.state = {
      showModalText: false,
      visitDate: {},
      showProgressBar: false,
      progressBarRatio: 55,
      showModalAlert: false,
      strUrl: '',
      strAlertTitle: '???',
      strAlertText: '???',
      showModalUrl: false,
      strProgressMessage: 'Loading...',
      patientInfo: {},
      showModalFile: false,
      imgUrl: this.context?.imgLink
    };
  }

  buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.ktx,.dcm,.nii,.hdr,.h,.img,.gz');
    fileSelector.setAttribute('multiple', '');
    console.log("fileSelector", fileSelector);
    fileSelector.onchange = this.handleFileSelected;
    return fileSelector;
  }

  componentDidMount() {
    this.m_fileSelector = this.buildFileSelector();
    const fileNameOnLoad = this.m_fileNameOnLoad;
    // console.log(`UiOpenMenu. componentDidMount. fnonl = ${fileNameOnLoad}`);
    if ((fileNameOnLoad.length > 0) && (this.state.onLoadCounter > 0)) {
      this.setState({ onLoadCounter: 0 });
      const TIMEOUT_MS = 50;
      setTimeout(this.loadFromUrl(fileNameOnLoad), TIMEOUT_MS);
    }
  }

  onButtonLocalFile(evt) {
    //  console.log('onButtonLocalFile started');
    evt.preventDefault();
    this.m_fileSelector.click();
  }

  handleFileSelected(evt) {
    console.log("evtevt", evt.target.files);
    if (evt.target.files !== undefined) {
      let numFiles = evt.target.files.length;
      console.log(`UiOpenMenu. Trying to open ${numFiles} files`);
      if (numFiles <= 0) {
        return;
      }
      console.log(`UiOpenMenu. handleFileSelected. file[0] = ${evt}`);
      this.m_volumeSet = new VolumeSet();
      if (numFiles === 1) {
        const file = evt.target.files[0];
        this.m_fileName = file.name;

        console.log("this.m_fileName", evt.target.files);

        //  read gzip
        if (this.m_fileName.endsWith('.gz')) {
          // here will be result raw buffer
          this.m_unzippedBuffer = null;

          // remove last 3 chars form file name string
          this.m_fileName = this.m_fileName.slice(0, -3);

          const store = this.props;

          const gunzip = zlib.createGunzip();
          createReadStream(file).pipe(gunzip);
          gunzip.on('data', (data) => {
            // progress
            const uiapp = store.uiApp;
            if (this.m_unzippedBuffer == null) {
              uiapp.doShowProgressBar('Read gzip...');
            } else {
              const readSize = this.m_unzippedBuffer.length;
              const allSize = file.size;
              const KOEF_DEFLATE = 0.28;
              const ratio100 = Math.floor(readSize * 100.0 * KOEF_DEFLATE / allSize);
              uiapp.doSetProgressBarRatio(ratio100);
            }

            // read the data chunk-by-chunk
            // data is Uint8Array
            const dataSize = data.length;
            if (this.m_unzippedBuffer == null) {
              // create buffer from first ungzipped data chunk
              this.m_unzippedBuffer = new Uint8Array(dataSize);
              this.m_unzippedBuffer.set(data, 0);
            } else {
              // append buffer from 2,3,... ungzipped data chunks
              const dataCollectedSize = this.m_unzippedBuffer.length;
              const arrNew = new Uint8Array(dataCollectedSize + dataSize);
              arrNew.set(this.m_unzippedBuffer, 0);
              arrNew.set(data, dataCollectedSize);
              this.m_unzippedBuffer = arrNew;
            }
          });
          gunzip.on('close', () => {
            console.log('gzip on close');
          });

          gunzip.on('end', () => {
            // close progress
            const uiapp = store.uiApp;
            uiapp.doHideProgressBar();

            // now all chunks are read. Need to check raw ungzipped buffer
            const sizeBuffer = this.m_unzippedBuffer.length;
            if (sizeBuffer < 128) {
              console.log('Too small ungzipped data: ' + sizeBuffer.toString() + ' bytes. canat read volume data');
              return;
            }
            // check correct nifti header after extract raw bytes from gzip
            const headTemplate = [0x00, 0x00, 0x01, 0x5c];
            let correctHead0 = true;
            for (let i = 0; i < 4; i++) {
              if (this.m_unzippedBuffer[i] !== headTemplate[i]) {
                correctHead0 = false;
              }
            }
            let correctHead1 = true;
            for (let i = 0; i < 4; i++) {
              if (this.m_unzippedBuffer[i] !== headTemplate[3 - i]) {
                correctHead1 = false;
              }
            }
            if (!correctHead0 && !correctHead1) {
              console.log('Wrong nifi header, cant read gzipped file');
              return;
            }
            console.log('ungzip done with ' + sizeBuffer.toString() + ' bytes. Correct nifti header detected');
            // process raw data buffer
            this.onFileReadSingleBuffer(this.m_unzippedBuffer);
          });
          return;
        } // if gzipped file

        this.m_fileReader = new FileReader();
        this.m_fileReader.onloadend = this.onFileContentReadSingleFile;
        console.log("filefile", file);
        this.m_fileReader.readAsArrayBuffer(file);
      } else {
        // not single file was open
        this.m_files = Array.from(evt.target.files); // FileList -> Array
        console.log("this.m_files", this.m_files);
        this.m_fileIndex = 0;
        this.m_numFiles = numFiles;
        this.m_fileReader = new FileReader();
        // if multiple files, create Dicom loader
        this.m_loader = null;
        if (evt.target.files[0].name.endsWith(".dcm")) {
          console.log("evt.target", evt.target);

          // remove non-dcm files
          let numFilesNew = 0;
          for (let i = numFiles - 1; i >= 0; i--) {
            if (this.m_files[i].name.endsWith(".dcm")) {
              numFilesNew++;
            } else {
              this.m_files.splice(i, 1);
            }

          }
          numFiles = numFilesNew;
          this.m_numFiles = numFilesNew;

          this.m_loader = new LoaderDicom(numFiles);
          const dicomInfo = this.m_loader.m_dicomInfo;

          // save dicomInfo to store
          const store = this.props;
          store.dispatch({ type: StoreActionType.SET_DICOM_INFO, dicomInfo: dicomInfo });

          // save dicom loader to store
          store.dispatch({ type: StoreActionType.SET_LOADER_DICOM, loaderDicom: this.m_loader });

          this.m_fileReader.onloadend = this.onFileContentReadMultipleDicom;
        } else if ((evt.target.files[0].name.endsWith(".hdr")) || (evt.target.files[0].name.endsWith(".img"))) {
          this.m_loader = new LoaderHdr(numFiles);
          this.m_fileReader.onloadend = this.onFileContentReadMultipleHdr;
        }

        //const vol = new Volume();
        //this.m_volume = vol;
        this.m_volumeRoi = null;

        const file = evt.target.files[0];
        this.m_fileName = file.name;
        this.m_fileReader.readAsArrayBuffer(file);
      } // if num files > 1
    } // if event is mnot empty
  }

  UNSAFE_componentWillMount() {
    let fileNameOnLoad = '';
    const strSearch = window.location.search;
    if (strSearch.length > 0) {
      const strReg = /\\?url=(\S+)/;
      const arr = strSearch.match(strReg);
      if (arr === null) {
        console.log('arguments should be in form: ?url=www.xxx.yy/zz/ww');
        return;
      }
      fileNameOnLoad = arr[1];
      const regA = /^((ftp|http|https):\/\/)?(([\S]+)\.)?([\S]+)\.([A-z]{2,})(:\d{1,6})?\/[\S]+/;
      const regB = /(ftp|http|https):\/\/([\d]+)\.([\d]+)\.([\d]+)\.([\d]+)(:([\d]+))?\/([\S]+)/;
      const isValidA = fileNameOnLoad.match(regA);
      const isValidB = fileNameOnLoad.match(regB);
      if ((isValidA === null) && (isValidB === null)) {
        console.log(`Not valid URL = ${fileNameOnLoad}`);
        return;
      }
      this.m_fileNameOnLoad = fileNameOnLoad;
    }
  }

  setVisitDate(vis) {
    this.setState({ visitDate: vis })
  }

  componentDidMount() {
    const store = this.m_store;
    if (store === null) {
      console.log('UiApp. componentDidMount. store is NULL');
    }
    store.dispatch({ type: StoreActionType.SET_UI_APP, uiApp: this });

    // browser detector
    const browserDetector = new BrowserDetector();
    this.isWebGl20supported = browserDetector.checkWebGlSupported();
    if (!this.isWebGl20supported) {
      this.setState({ strAlertTitle: 'Browser compatibility problem detected' });
      this.setState({ strAlertText: 'This browser not supported WebGL 2.0. Application functionality is decreased and app can be unstable' });
      this.onShowModalAlert();
    } else {
      const isValidBro = browserDetector.checkValidBrowser();
      if (!isValidBro) {
        this.setState({ strAlertTitle: 'Browser compatibility problem detected' });
        this.setState({ strAlertText: 'App is specially designed for Chrome/Firefox/Opera/Safari browsers' });
        this.onShowModalAlert();
      }
    }
  }

  onShowModalText() {
    this.setState({ showModalText: true });
  }

  onSetPatientInfo(patientInf) {
    console.log("patientInfo from ui", patientInf);
    this.setState({ patientInfo: { ...patientInf } });
  }

  // componentDidUpdate() {
  //   console.log("this.props.patientInfo", this.props.patientInfo);
  //   if (Object.keys(this.props.patientInfo).length && 
  //   (JSON.stringify(this.props.patientInfo) !== JSON.stringify(this.state.patientInfo)) && 
  //   this.props.patientId !== this.state.patientInfo.patientId) {
  //     this.onSetPatientInfo(this.props.patientInfo)
  //   }
  // }

  onHideModalText() {
    this.setState({ showModalText: false });
  }

  onShowModalAlert() {
    this.setState({ showModalAlert: true });
  }

  onHideModalAlert() {
    this.setState({ showModalAlert: false });
  }

  onOpenModalFile() {
    this.setState({ showModalFile: true });
  }

  onCloseModalFile() {
    this.setState({ showModalFile: false });
  }

  doShowProgressBar(strProgressMsg) {
    if ((strProgressMsg === undefined) || (strProgressMsg === null)) {
      console.log('doShowProgressBar: need argument - strProgressMsg');
      return;
    }
    this.setState({ strProgressMessage: strProgressMsg });
    this.setState({ showProgressBar: true });
  }

  doHideProgressBar() {
    // console.log('doHideProgressBar');
    this.setState({ showProgressBar: false });
  }

  handleClickLoadF() {
    console.log("this.button.current", this.button);
    // use the child button ref to trigger a click
  }

  /**
   * 
   * @param {number} ratio - in [0..99] range
   */
  doSetProgressBarRatio(ratio) {
    // console.log(`doSetProgressBarRatio: ${ratio}`);

    // show progress bar if it was hidden but need to show some non-0, non-100 progress
    if ((ratio >= 0) && (ratio <= 99)) {
      if (this.state.showProgressBar === false) {
        this.setState({ showProgressBar: true });
      }
    }
    this.setState({ progressBarRatio: ratio });
  }

  onModalUrlHide() {
    this.setState({ showModalUrl: false });
  }

  onModalUrlShow() {
    const UserContextAdd = this.context
    this.setState({ strUrl: UserContextAdd.imgLink });
    this.setState({ showModalUrl: true });
  }

  /**
   * Main component render func callback
   */
  render() {
    const store = this.props;
    this.m_store = store;
    const isLoaded = store.isLoaded;
    const fileName = store.fileName;
    const arrErrorsLoadedd = store.arrErrors;

    console.log("this.props.patientInfo", this.props.patientInfo);

    const strProgressMsg = this.state.strProgressMessage;

    const objPrgBarVis =
      <Row>
        <Col xs xl sm md lg="12" style={{ width: '100%' }}>
          {strProgressMsg}
          <ProgressBar animated variant="success"
            now={this.state.progressBarRatio} label={`${this.state.progressBarRatio}%`} />
        </Col>
      </Row>
    const objProgressBar = (this.state.showProgressBar) ? objPrgBarVis : <p></p>;

    const jsxNavBarReact =
      <Container fluid="true" style={{ height: '100%', minHeight: '100%' }}>
        <Navbar className="p-3 mb-2 bg-dark text-white" variant="light" expand="lg" style={{ flexDirection: 'row' }}>

          <Navbar.Toggle className="text-white" aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="text-white" id="basic-navbar-nav">
            <Nav className="mr-auto text-white" style={{ width: '50%', margin: '0 5%', display: 'flex', justifyContent: 'space-between' }}>
              {/* <UiUser user={this.props.user} /> */}
              <UiOpenMenu showModalUrl={this.state.showModalUrl} onModalUrlHide={this.onModalUrlHide} onModalUrlShow={this.onModalUrlShow}
                handleClickLoadF={this.handleClickLoadF} refBtn={this.button} fileNameOnLoad={this.m_fileNameOnLoad} />
              {/* <button style={{ backgroundColor: 'inherit', color: 'white', border: 'none' }} onClick = {this.onOpenModalFile}>View File</button> */}

              <UiSaveMenu />
              {/* <UiReportMenu /> */}
              {(store.modeView === ModeView.VIEW_2D) ? <UiFilterMenu /> : <p></p>}
              {(isLoaded && this.isWebGl20supported) ? <UiViewMode /> : <p></p>}
              <Navbar.Text style={{ marginLeft: '15px' }} className="d-none d-sm-block">
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Brand style={{ alignSelf: 'flex-end' }}>
            <UiAbout />
          </Navbar.Brand>

        </Navbar>
        {/* <SideNavBar onButtonLocalFile = {this.onButtonLocalFile} /> */}

        <FileView onCloseModalFile={this.onCloseModalFile} onOpenModalFile={this.onOpenModalFile} showModalFile={this.state.showModalFile} />


        {objProgressBar}
        <HeaderPatient setVisitDate = {this.setVisitDate} visitDate = {this.state.visitDate}  />
        {(isLoaded) ? <UiMain /> : <p></p>}
        {(arrErrorsLoadedd.length > 0) ? <UiErrConsole /> : <p></p>}

      </Container>;

    return jsxNavBarReact;
  }
}

export default connect(store => store)(UiApp);
