/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import DocumentsModal from "../DocumentsModal";
import { axiosInstance, getAuthorizationHeader, getToken } from "../../config/api";
import UserContext from "../../UserContext/UserContext";

function InfiniteScroller(props) {
  const {
    startDate,
    setVisitDate,
    setSelectId,
    handleClickLoadF,
    setIsVisible,
    refBtn,
    onModalUrlShow,
  } = props;

  const [items, setItems] = useState([]);
  const [visitId, setVisitId] = useState("-1");
  const [openDocuments, setOpenDocuments] = useState(false);
  const [page, setPage] = useState(1);
  const [documentDetails, setDocumentDetails] = useState([]);
  const loaderRef = useRef(null);
  const targetRef = useRef(null);


  const contextType = useContext(UserContext);
  const { setUser ,
    setUserSelected } = contextType;



  useEffect(() => {
    const observer = new IntersectionObserver(  (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 } );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (openDocuments === true && visitId > 0) {
      axiosInstance
        .get(`/Api/people/peopledocuments/${visitId}` , {
          headers: {
             ...getAuthorizationHeader()//the token is a variable which holds the token
          }
         })
        .then((res) => {
          console.log("res" , res);
          console.log("res.status" , res.status);
          console.log("res.data.data" , res.data);
          if (res.status === 200) {
            setDocumentDetails(    res.data.data.map((kk) => ({
                id: kk.patientId,
                documentName: kk.imageTestName1,
                documentCategoryName: kk.imageTestName2,
                documentTypeName: kk.fileExt,
                img: kk.fileUrl,
              }))  );
          }
          // setPatientInfo(res.data.data);
        }).catch(()=>{
          alert("some thing went")
        })
    }
  }, [openDocuments, visitId]);


  useEffect(() => {
    let formData = new FormData();
    if (startDate === null) {
      axiosInstance
        .post(`/Api/People/PeoplesPerVisits?page=${page}`, formData,
        {
          headers: {
             ...getAuthorizationHeader()//the token is a variable which holds the token
          }
         })
        .then((res) => {
          setItems([...items, ...res.data.data]);
          if (page === 1 && targetRef.current) {
            targetRef.current.scrollIntoView();
          }
          console.log(res);
        });
    } else {

      formData.append("visitDate", startDate?.toISOString().split("T")[0]);

      console.log("formData after" , formData);
      axiosInstance
        .post(`/Api/People/PeoplesPerVisits?page=${1}`, formData ,  {
          headers: {
             ...getAuthorizationHeader()//the token is a variable which holds the token
          }
         })
        .then((res) => {
          console.log("res?.data?.data with date" , res?.data?.data);
          setItems(res?.data?.data);
        });
    }
  }, [page, startDate]);

 


console.log("items for list" , items);
console.log("startDate" , startDate);
  return (
    <div style={{ marginTop: "20px" }}>
      <div ref={targetRef} style={{ height: "calc(100vh - 280px)", overflowY: "scroll" }}>
        <ul>
          {items.map((user) => (
            <>
              <List
                id={user.patientId}
                onClick={() => {
                  setUser(user.patientId);
                  setSelectId(user.patientId);
                  setUserSelected(user)
                  setVisitDate({
                    visitDate: user.visitDate,
                    visitTime: user.visitTime,
                    visitStatus: user.visitStatus,
                  });
                  setOpenDocuments(true);
                  setVisitId(user.visitId);
                }}
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  margin: "auto",
                }}
                aria-label="contacts">
                <ListItemButton>
                  <ListItem disablePadding>
                    <ListItemAvatar>
                      <Avatar
                        alt="Travis Howard"
                        sx={{ width: "60px", height: "60px" }}
                        src={user.img !== null ? user.img : ""}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      inset
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="p"
                            variant="body1"
                            color="text.primary">
                            {user.firstName +
                              " " +
                              user.fatherName +
                              " " +
                              user.familyName}
                          </Typography>
                          <br />
                          <Typography
                            sx={{ display: "inline", fontSize: "14px" }}
                            component="span"
                            variant="body1"
                            color="#b3b1c2">
                            visitTime: {user.visitTime} Status:{" "}
                            {user.visitStatus}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </ListItemButton>
              </List>
            </>
          ))}
        </ul>
        <div ref={loaderRef}>Loading...</div>
      </div>
      <DocumentsModal
        refBtn={refBtn}
        onModalUrlShow={onModalUrlShow}
        handleClickLoadF={handleClickLoadF}
        setIsVisible={setIsVisible}
        open={openDocuments}
        setOpen={setOpenDocuments}
        docDetails={documentDetails}
      />
    </div>
  );
}

export default InfiniteScroller;
