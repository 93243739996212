import axios from "axios";

const baseURL = "https://i-doctor.app";

export const getToken = () => {
  return localStorage.getItem("authToken")
    ? localStorage.getItem("authToken")
    : null;
};

export const getAuthorizationHeader = () => {
  const currentUser = getToken();
  return {
    Authorization: `Bearer ${currentUser || ""}`,
  };
};

export const axiosInstance = axios.create({
  baseURL,
});
