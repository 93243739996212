export class AuthTokenStorage {
    static getAuthToken() {
      return localStorage.getItem('authToken');
    }
  
    static setAuthToken(token) {
      if (token) {
        localStorage.setItem('authToken', token);
      } else {
        localStorage.removeItem('authToken');
      }
    }
  }