/* eslint-disable no-unused-vars */
import * as React from "react";
import Modal from "@mui/joy/Modal";
// eslint-disable-next-line no-unused-vars
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { AiFillCheckCircle } from "react-icons/ai";
import { Divider } from "@mui/material";
import { Box, Button, ModalDialog } from "@mui/joy";

export default function PrevModal(props) {
  const { open, setOpen, patientDetails } = props;

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description">
          <Typography id="alert-dialog-modal-title" component="h2">
            Medical History
          </Typography>
          <Divider />
          {patientDetails.length === 0 ? (
            <Typography id="alert-dialog-modal-description" textColor="red">
              There is no past History record related to the patient
            </Typography>
          ) : (
            patientDetails.map((patient) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: "5px 0px 5px 0px",
                }}>
                <AiFillCheckCircle />
                {patient}
              </div>
            ))
          )}

          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }}>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
