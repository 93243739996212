/* eslint-disable no-unused-vars */
import * as React from "react";
import Modal from "@mui/joy/Modal";
// eslint-disable-next-line no-unused-vars
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { AiFillCheckCircle } from "react-icons/ai";
import { Divider } from "@mui/material";
import { Box, Button, IconButton, ModalDialog } from "@mui/joy";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GrFormView } from "react-icons/gr";
import UserContext from "../UserContext/UserContext";
import FileTools from "../engine/loaders/FileTools";
import VolumeSet from "../engine/VolumeSet";

function createData( id,
  documentName,
  documentCategoryName,
  documentTypeName,
  createDate) {
  return {
    id,
    documentName,
    documentCategoryName,
    documentTypeName,
    createDate,
  };
}

export default function DocumentsModal(props) {
  console.log(props);

  const userImageSelect = React.useContext(UserContext);

  const { setImgLink } = userImageSelect;

  const {
    open,
    setOpen,
    docDetails,
    setIsVisible,
    onModalUrlShow,
    handleClickLoadF,
    refBtn,
  } = props;

  
  const [rows, setRows] = React.useState([]);
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    setRows(  docDetails.map((kk) =>
        createData(  kk.id,
          kk.documentName,
          kk.documentCategoryName,
          kk.documentTypeName,
          kk.createDate  ) )   );
    setImages(docDetails.map((mm) => mm.img));
  }, [docDetails]);

  const handelClose = () => {
    setOpen(false);
    console.log("close");
   };

  const handleChangeConnect = (id) => {
    setImgLink(id);
    setIsVisible(false);
    setOpen(false);
    onModalUrlShow();
    handleClickLoadF();
    refBtn?.current?.addEventListener("click", handleClickLoadF);
  };

  React.useEffect(() => {
    return refBtn?.current?.removeEventListener("click", handleClickLoadF);
  }, []);

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description">
          <Typography id="alert-dialog-modal-title" component="h2">
            Patient Documents
          </Typography>
          <Divider />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File No</TableCell>
                  <TableCell align="right">File Name</TableCell>
                  <TableCell align="right">Medical Type</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Uploaded Date</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow : 'scroll' }}>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.documentName}</TableCell>
                    <TableCell align="right">
                      {row.documentCategoryName}
                    </TableCell>
                    <TableCell align="right">{row.documentTypeName}</TableCell>
                    <TableCell align="right">{row.createDate}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color={"secondary"}
                        onClick={() => {
                          handleChangeConnect(images[index]);
                        }}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }} >
          <Button variant="plain" color="neutral" onClick={handelClose}>
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
