import React, { Component } from 'react'

const UserContext = React.createContext()

class UserProvider extends Component {
    // Context state
    state = {
        user: {},
        token: localStorage.getItem('authToken'),
        imgLink: '',
        userselected : ''
    }

    // Method to update state
    setUser = (user) => {
        this.setState(() => ({ user }))
    }

    setImgLink = (img) => {
        this.setState(() => ({ imgLink: img }))
    }

    setUserSelected = (user) => {

        this.setState(() => ({ userselected: user }))
    }

    setToken = (token) => {
        this.setState(() => ({ token }))
    }

    render() {
        const { children } = this.props
        const { user } = this.state
        const { token } = this.state
        const { imgLink } = this.state
        const { userselected } = this.state
        const { setUser } = this
        const { setToken } = this
        const { setImgLink } = this
        const { setUserSelected } = this

        return (
            <UserContext.Provider
                value={{
                    user,
                    setUser,
                    token,
                    setToken,
                    imgLink,
                    setImgLink,
                    userselected,
                    setUserSelected
                }}
            >
                {children}
            </UserContext.Provider>
        )
    }
}

export default UserContext

export { UserProvider }