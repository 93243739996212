/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Logger } from "logging-library";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import Modal from "@mui/joy/Modal";
import { ModalDialog } from "@mui/joy";
import "./styleFile.css";
import UserContext from "../UserContext/UserContext";

// const file = "https://localhost:44392\\Documents\\43\\31397033032469217682023-01-25 21-50-03.mp4";
const type = "mp4";

const substrings = ["docx", "mp4", "webm", "mp3", "png", "jpeg", "bmp"];

export const FileView = (props) => {
  const userImageSelect = React.useContext(UserContext);

  const { setImgLink, imgLink } = userImageSelect;

  const { onOpenModalFile, onCloseModalFile, showModalFile } = props;

  const [file, setFile] = useState("");

  useEffect(() => {
    if (
      substrings?.some(function (v) {
        return imgLink?.indexOf(v) >= 0;
      })
    ) {
      let aa = imgLink.lastIndexOf(".");
      setFile(imgLink);
    }
  }, [imgLink]);

  // const handleView = () => {
  //   onOpenModalFile();
  // };

  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };

  return (
    <div style={{ margin: "auto" }}>
      <Modal
        open={showModalFile}
        onClose={onCloseModalFile}
        sx={{ margin: "auto", height: "500px", width: "80%", margin: "auto" }}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
          size="sm">
          <div style={{ height: "100%", width: "100%", margin: "auto" }}>
            {file.length > 0 && (
              <FileViewer
                fileType={type}
                filePath={file}
                errorComponent={CustomErrorComponent}
                onError={onError}
              />
            )}
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
};
