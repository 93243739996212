
const Modes3d = {
  NA: -1,
  ISO: 0,
  RAYCAST: 1,
  RAYFAST: 2,
  EREASER: 3
};

export default Modes3d;
